<template>
    <page heading="Temporary Images">
        <gallery :items="images" v-if="images.length" />
    </page>
</template>

<script>
import { mapState } from 'vuex';
import store from '../../store';
import Page from '../../layouts/Page.vue';
import Gallery from '../../components/Presentation/Gallery.vue';

export default {
    components: { Gallery, Page },
    async beforeRouteEnter() {
        await store.dispatch('temporaryImages/fetchImages');
    },
    computed: {
        ...mapState('temporaryImages', ['images']),
    },
};
</script>
